import { amgApi } from "@/service/axios";
class OfficeHoursService {    

    async setOfficeHours(params) {
        try {
            const data = await amgApi.post("commons/set-claim-hours", params);
            return data;
        } catch (error) {
            console.log("Error setOfficeHours",error);
            throw error;
        }
    }
    async getTrackingOfficeHours(params) {
        try {
            const data = await amgApi.post("commons/get-tracking-claim-hours", params);
            return data;
        } catch (error) {
            console.log("Error getTrackingOfficeHours", error);
            throw error;
        }
    }
    async getOfficeHoursPerDay(params) {
        try {
            const data = await amgApi.post("commons/get-claim-hours-per-day",params);
            return data;
        } catch (error) {
            console.log("Error getOfficeHoursPerDay", error);
            throw error;
        }
    }
    async getDay(params) {
        try {
            const data = await amgApi.post("commons/get-day", params);
            return data.data;
        } catch (error) {
            console.log("Error getDay" , error);
            throw error;
        }
    }
}
export default new OfficeHoursService();

