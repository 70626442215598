<template>
    <b-card border-variant="light" no-body class="selectPadding" v-if="isBusy">
      <b-skeleton animation="fade" width="90%"></b-skeleton>
    </b-card>
  </template>
  <script>
  export default {
    props: {
      isBusy: {
        type: Boolean,
      },
    },
  };
  </script>
  <style scoped>
  .selectPadding {
    padding: 10px 0 0px 10px;
  }
  </style>
  
  