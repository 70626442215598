<template>
    <div>
      <b-modal
        v-model="modalCenter"
        modal
        title="TRACKING"
        size="lg"
        modal-class="modal-primary "
        hide-footer
        title-tag="h3"
        @hidden="closeModalTracking"
      >
        <b-row class="mt-1 px-">
          <!-- Client -->
          <b-col cols="1">
            <h5 class="mt-1 ml-1">Day</h5>
          </b-col>
          <b-col cols="2">
            <p
              class="
                rounded
                text-primary
                border-primary
                font-medium-1
                text-center
                py-1
              "
            >
              {{ day }}
            </p>
          </b-col>
          <b-col cols="9" />
        </b-row>
        <!-- Table -->
        <b-table
          ref="refTracking"
          small
          :items="myProvider"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column: Created By -->
          <template #cell(created_at)="data">
            <div>
              <small>{{ data.item.created_at | myGlobalWithHour }}</small>
            </div>
          </template>
        </b-table>
      </b-modal>
    </div>
  </template>
  
  <script>
  import OfficeHoursService from "../../service/office-hours.service";
  export default {
    props: {
      trackingData: {
        type: Object,
      },
      day: {
        type: String,
      },
    },
    data() {
      return {
        modalCenter: true,
        fields: [
          { label: "CREATED BY", key: "full_name" },
          { key: "from", thClass: "text-center", tdClass: "text-center" },
          { key: "to", thClass: "text-center", tdClass: "text-center" },
          {
            label: "Created at",
            key: "created_at",
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
      };
    },
  
    methods: {
      closeModalTracking() {
        this.$emit("hideModal");
      },
      async myProvider() {
        try {
          const result = await OfficeHoursService.getTrackingOfficeHours({
            day: this.trackingData.id,
            module: this.$route.matched[0].meta.module 
          });
          return result.data;
        } catch (error) {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong with tracking!"
          );
          throw error;
        }
      },
    },
  };
  </script>
  